import React from "react"
import MindBodyWidget from "../../components/mindbody"
import TeamImg from "../../images/team.jpeg"
import Layout from '../../components/layout-team-access'


const Classes = () => (
  <Layout
    title="Bachata &amp; Salsa Class Schedule | RF Dance"
    description="Sign up for a bachata or salsa class with RF Dance online." 
    pathname="classes"
    img={TeamImg}
    noHero="no-hero">
    <div className="container" style={({'minHeight':`50vh`})}>
      <p style={({
        'position': `absolute`,
        'margin': `4em auto`,
        'zIndex': -1,
        'left': `50%`,
        'marginLeft': `-70px`
      })}>Loading classes...</p>
      <MindBodyWidget 
        type="schedules" 
        widgetPartner="object" 
        widgetId="d48507309b0" 
        widgetVersion="1" />
    </div>
  </Layout>
)

export default Classes